<template>
  <div class="characteristics-wrapper">
    <ProductCharacteristic
      :characteristics="groups?.short?.items"
      :max-elements="isShownAll ? undefined : 10"
      big-value-font
      no-button
      wrap-value
      half-width-mobile
    />
    <div v-if="isShownAll">
      <div
        v-for="(item, key) in groups?.other"
        :key="key"
      >
        <p
          v-if="item?.items?.length"
          class="mm-headline-4 models-page__h4"
        >
          {{ item.name }}
        </p>
        <ProductCharacteristic
          v-if="item?.items?.length"
          :characteristics="item?.items"
          big-value-font
          no-button
          wrap-value
          half-width-mobile
        />
      </div>
    </div>
    <button
      v-if="overallCount > 10"
      class="btn btn-secondary"
      @click="isShownAll = !isShownAll"
    >
      {{ isShownAll ? 'Свернуть все характеристики' : 'Показать все характеристики' }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { IProductCharacteristicGroups } from 'shared/models/product.model';
import ProductCharacteristic from 'shared/components/ProductCharacteristic.vue';

const props = defineProps<{
  groups: IProductCharacteristicGroups;
}>();

const overallCount = computed(
  () => props.groups?.short?.items?.length + props.groups?.other?.reduce((acc, group) => acc + group?.items?.length, 0),
);
const isShownAll = ref(false);
</script>

<style lang="scss" scoped>
.characteristics-wrapper {
  .btn {
    margin-top: 28px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  .mm-headline-4 {
    margin-top: 20px;
    font-weight: 500;
  }
}

@media screen and (min-width: 768px) {
  .characteristics-wrapper {
    :deep(.mm-product-characteristic-wrapper) {
      justify-content: flex-start !important;

      &::before {
        width: calc(50% - 4px) !important;
      }
    }

    :deep(.mm-product-characteristic-wrapper__label-column) {
      width: 50%;
    }

    :deep(.mm-product-characteristic-wrapper__value-column) {
      flex-basis: 50% !important;
      align-items: flex-start !important;

    }

    :deep(.mm-product-characteristic-value) {
      padding-left: 0 !important;
    }

    :deep(.mm-product-characteristic-value) {
      text-align: left !important;
    }

    :deep(.mm-product-characteristic-mark) {
      text-align: left !important;
    }
  }
}
</style>
