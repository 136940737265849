<template>
  <div class="mm-related-items">
    <div class="mm-related-items__header">
      <h4 class="mm-related-items__title">
        {{ label }}
      </h4>

      <CarouselButtons
        v-if="!isMobileListing"
        class="mm-related-items__buttons"
        :disabled-left="currentSlide === 0"
        :disabled-right="currentSlide + showCount >= products?.length"
        @right-click="onScrollRightClick"
        @left-click="onScrollLeftClick"
      />
    </div>

    <div class="mm-related-items__wrapper">
      <Carousel
        ref="carouselRef"
        v-model="currentSlide"
        :mouse-drag="false"
        :breakpoints="breakpoints"
        :transition="300"
        :items-to-show="showCount"
        snap-align="start"
        class="mm-related-items__carousel"
      >
        <Slide
          v-for="(product, index) in products"
          :key="product.id"
          class="mm-related-items__slide"
        >
          <NuxtLink
            :to="getProductLink(product)"
            class="mm-related-items-item__link"
          >
            <ProductCard
              v-if="isShowCard(index)"
              :id="product?.id?.toString()"
              :vendor-code="product?.vendorCode"
              :name="product?.name"
              :price="product?.priceInfo?.min"
              :marketplace-quantity="product?.offerCount"
              disabled-carousel
              :specifications="product?.specifications"
              :pictures="getProductImages(product.images)"
              :mtr-code="product?.mtrCode"
              is-show-price
              class="mm-related-items-item__card"
              @product:click="onProductClick(product.id, product.slug, $event)"
            />
          </NuxtLink>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IProduct, IRelatedProductClickEvent } from 'shared/models/product.model';
import ProductCard from 'shared/components/ProductCard.vue';
import CarouselButtons from 'shared/components/CarouselButtons.vue';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { Carousel, Slide } from 'vue3-carousel';
import { IMaxMartImageItem } from 'shared/models/maxMartImageItem';

const props = withDefaults(
  defineProps<{
    products: Array<IProduct>;
    label: string;
    productClickFn?: (product: IRelatedProductClickEvent) => void | Promise<void>;
    ociMode?: boolean;
  }>(),
  {
    products: [] as Array<IProduct>,
  },
);

const { isMobile, isSmallMobile, isDesktop, isTablet, isLargeDesktop } = WindowWidthManager.getAdaptivaRefs();
const currentSlide = ref(0);
const carouselRef = ref();
const additionalBorderSlidesBuffer = 2;
const breakpoints = {
  320: {
    itemsToShow: 1.5,
  },
  768: {
    itemsToShow: 3,
  },
  1280: {
    itemsToShow: 4,
  },
  1600: {
    itemsToShow: 5,
  },
};

const isMobileListing = computed(() => isMobile.value || isSmallMobile.value);
const showCount = computed(() => {
  if (isLargeDesktop.value) {
    return 5;
  }

  if (isDesktop.value) {
    return 4;
  }

  if (isTablet.value) {
    return 3;
  }

  if (isMobile.value || isSmallMobile.value) {
    return 1.5;
  }

  return 5;
});

function onScrollRightClick(): void {
  carouselRef.value?.next();
}

function isShowCard(index: number): boolean {
  return index >= currentSlide.value - (showCount.value + additionalBorderSlidesBuffer)
    && index <= currentSlide.value + (showCount.value + additionalBorderSlidesBuffer);
}

function getProductImages(images: Array<IMaxMartImageItem>): Array<string> {
  return isMobileListing.value
    ? images?.[0]?.url
      ? [images?.[0]?.url] : []
    : images?.map((el) => el.url);
}

function onScrollLeftClick(): void {
  carouselRef.value?.prev();
}

async function onProductClick(productId: number, productSlug: string, data: { id: number, event: PointerEvent}): Promise<void> {
  if (!props.productClickFn) {
    return;
  }


  data?.event?.preventDefault();
  await props.productClickFn?.({ id: productId, slug: productSlug });
}

function getProductLink(product: IProduct): string | undefined {
  if (props.productClickFn) {
    return;
  }

  return props.ociMode ? `/oci${product.slug}` : product.slug;
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-related-items {
  max-width: 1584px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 32px;
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 0;
  }

  &__wrapper {
    border-radius: 8px;
    border: 1px solid $gray-200;
    display: flex;
    align-items: flex-start;
    padding: 0 12px;
    width: 100%;
    height: max-content;
  }

  &__carousel {
    width: 100%;
    position: relative;

    :deep(.carousel__viewport) {
      border-radius: 8px;
    }

    :deep(.carousel__track) {
      list-style-type: none;
    }
  }

  &__slide {
    position: relative;

    &.carousel__slide--visible {
      &:first-child {
        .mm-product-card {
          border-left: none;
        }
      }
    }

    &.carousel__slide--active {
      .mm-product-card {
        border-left: none;
      }
    }

    &.carousel__slide--next {
      .mm-product-card {
        border-left: none;
      }
    }

    &.carousel__slide > div {
      width: 100%;
      height: 100%;
    }

    &.carousel__slide:last-child {
      &:nth-child(3), &:nth-child(2), &:nth-child(1), &:nth-child(4) {
        border-right: 1px solid $gray-200;
      }
    }
  }

  &-item {
    &__link {
      text-decoration: none;
      height: 100%;
    }

    &__card {
      border-radius: 0 !important;
      height: 100%;
      width: 100%;
      border-left: 1px solid $gray-200;
      box-shadow: none !important;

      :deep(.mm-product-card-inner) {
        width: 100%;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-related-items {
    &__slide {
      &:last-child {
        &:nth-child(3), &:nth-child(2), &:nth-child(1) {
          border-right: 1px solid $gray-200;
        }

        &:nth-child(4) {
          border-right: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-related-items {
    &__header {
      margin-bottom: 20px;
      padding-left: 48px;
      padding-right: 24px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__wrapper {
      margin-left: 32px;
      width: calc(100% - 32px);
    }

    &__slide {
      &:last-child {
        &:nth-child(2), &:nth-child(1) {
          border-right: 1px solid $gray-200;
        }

        &:nth-child(4), &:nth-child(3) {
          border-right: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-related-items {
    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__wrapper {
      padding: 0;

      .mm-product-card {
        padding: 20px;
      }
    }

    &__slide {
        &.carousel__slide {
          border-radius: 8px !important;
          border-right: none !important;
        }

        &.carousel__slide:last-child {
          &:nth-child(1) {
            border-right: 1px solid $gray-200 !important;
          }
        }

        &.carousel__slide--next {
          .mm-product-card {
            border-left: 1px solid $gray-200;
          }
        }
    }
  }
}
</style>
