<template>
  <div
    id="calculate_offers_form"
    class="calculate-inputs"
  >
    <div
      class="calculate-inputs__title"
      :class="{ 'hidden-price': !isShowPrice }"
    >
      <template v-if="!hideSelectRow && offersData?.items?.length">
        Предложения от {{ t(ERuLocales.SupplierOffer, offersData.items?.length) }}
      </template>
      <template v-else>
        Нет предложений от поставщиков
      </template>
    </div>

    <div
      v-if="!hideSelectRow && isShowPrice"
      class="calculate-inputs__wrapper"
    >
      <div class="calculate-inputs__section models-page__left">
        <div class="mm-body-regular-s calculate-inputs__label">
          Введите нужное количество товара
        </div>

        <TextField
          :model-value="productsCount ?? ''"
          default-value="1"
          :mask="EInputMasks.ItemsQuantity"
          is-dynamic-right-label
          hide-clear-icon-on-blur
          :mask-options="{
            min: 0,
            max: maxQuantity,
            mask: Number,
            thousandsSeparator: ' ',
          }"
          size="small"
          :right-label="unitName"
          input-spec-id="quantity_input"
          :max-fraction-digits="precision"
          separate-thousands
          lazy
          radix=","
          :disabled="disabledInput"
          @focus-change="$emit('update:focus', $event)"
          @update:model-value="$emit('update:productsCount', $event ?? '')"
        />
      </div>

      <div class="calculate-inputs__section models-page__right">
        <Checkbox
          :model-value="availableOffers"
          class="calculate-inputs__checkbox"
          :disabled="disabledAvailableOffersCheckbox"
          @update:model-value="$emit('update:availableOffers', $event)"
        >
          Только товары<br>&nbsp;в наличии
        </Checkbox>

        <Checkbox
          :model-value="directDelivery"
          class="calculate-inputs__checkbox"
          :disabled="disabledDirectDeliveryCheckbox"
          @update:model-value="$emit('update:directDelivery', $event)"
        >
          Только товары<br>&nbsp;с прямой поставкой
        </Checkbox>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IResultedOffersBody } from 'shared/models/product.model';
import { EInputMasks } from 'shared/enums/inputMasks.enum';
import { ISelectOption } from 'shared/models/select.model';
import { MAX_INTEGER_NUMBER } from 'shared/constants/number.const';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import Checkbox from 'shared/components/Checkbox.vue';

withDefaults(defineProps<{
  offersData: IResultedOffersBody;
  productsCount: string;
  selectOptions: Array<ISelectOption>;
  hideSelectRow: boolean;
  maxQuantity?: number;
  isLoading?: boolean;
  unitName: string;
  hiddenSelectCalcType?: boolean;
  isShowPrice?: boolean;
  precision?: number;
  availableOffers?: boolean;
  disabledAvailableOffersCheckbox?: boolean;
  directDelivery?: boolean;
  disabledDirectDeliveryCheckbox?: boolean;
  disabledInput?: boolean;
}>(), {
  maxQuantity: MAX_INTEGER_NUMBER,
  isShowPrice: true,
  precision: 0,
});

defineEmits<{
  (e: 'update:productsCount', value: string): void;
  (e: 'update:availableOffers', value: boolean): void;
  (e: 'update:directDelivery', value: boolean): void;
  (e: 'update:focus', value: boolean): void;
}>();

const { t } = useI18n();
</script>

<style lang="scss">
@import 'styles/base/common/variables';

$input-label-mobile: 131px;

.calculate-inputs {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 32px;
  background-color: $light-gray;
  border-radius: 8px;
  margin-bottom: 8px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $gray-200;
  }

  .flex-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .calculate-inputs__title {
    width: 100%;
    flex-shrink: 0;
    font-weight: 500;
    line-height: 28px;
    color: $text-black;
    font-size: 20px;
    margin-top: 0;

    &.hidden-price {
      margin: 0;
    }
  }

  &__section {
    align-items: flex-start;
    display: flex;
  }

  &__label {
    color: $light-green;
  }

  .mm-input {
    padding-bottom: 0;
  }

  .mm-input__label {
    top: 21px !important;
    right: 16px !important;
  }

  .models-page__left {
    margin-bottom: 12px;
    padding-right: 0;
    flex: 0 0 40%;

    .mm-body-regular-s {
      min-width: 133px;
    }

    .mm-input {
      min-width: 120px;
      width: 130px;
      height: 40px;

      .mm-input__input {
        background-color: $text-white;
        padding-left: 15px;
      }
    }
  }

  .models-page__right {
    flex: 1;
    gap: 40px;

    .mm-body-regular-s {
      max-width: 102px;
    }

    .calc-type-select {
      flex: 1;
    }
  }

  .models-page__left,
  .models-page__right {
    margin-top: 0;
    width: auto;
    display: flex;

    .mm-body-regular-s {
      margin-right: 24px;
    }
  }

  .calc-type-select .v-select .vs__dropdown-toggle {
    padding: 8px 16px;
    background-color: $text-white;
    width: 264px;
    height: 40px;
  }

  .calc-type-select .vs__dropdown-toggle .vs__selected-options {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__checkbox {
    flex: 1;

    .checkbox__input {
      span {
        background-color: $white;
      }
    }
  }
}

@media only screen and (max-width: 1599px) {
  .calculate-inputs {
    padding: 20px;
    flex-direction: column;

    &__wrapper {
      flex-direction: column;
      gap: 0;
    }

    .models-page__right {
      margin-left: 0;
      padding-left: 196px;
      flex-direction: column;
      gap: 12px;

      .calc-type-select {
        max-width: 278px;
      }

      .calculate-inputs__label {
        max-width: 172px !important;
        width: 172px !important;
        padding-right: 23px !important;
      }
    }

    .models-page__left {
      .calculate-inputs__label {
        max-width: 172px !important;
        width: 172px !important;
      }
    }

    .calc-type-select .vs__dropdown-toggle {
      max-width: 278px;
    }

    &__checkbox {
      br {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .calculate-inputs {
    .offers-table__label {
      max-height: 20px;
    }

    .calculate-inputs__section {
      margin-left: 0;
    }

    &__wrapper {
      flex-direction: column !important;
      gap: 0;
      padding-top: 0;
      border: none;

      .models-page__right {
        order: 1;
        padding-left: 192px;

        .calculate-inputs__label {
          padding-right: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .calculate-inputs {
    flex-direction: column;
    padding: 24px 32px;

    &__wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column !important;
    }

    .mm-select {
    padding-bottom: 0;
  }

    .models-page__left {
      display: flex;
      align-items: center;
      margin: 0;
      margin-right: 20px;
      order: 0;

      .mm-input {
        width: 128px;
      }

      .mm-body-regular-s {
        min-width: $input-label-mobile;
      }

      .mm-body-regular-s {
        margin-right: 19px;
        flex: 0 0 $input-label-mobile;
        max-width: $input-label-mobile !important;
      }
    }

    .models-page__right {
      flex: 1;
      display: flex;
      align-items: center;
      margin-top: 0;
      padding-left: 0;

      .calculate-inputs__label {
        padding-right: 0;
      }

      .mm-body-regular-s {
        margin-right: 19px;
        flex: 0 0 102px;
        max-width: 102px !important;
      }

      .calc-type-select .vs__dropdown-toggle {
        width: 276px;
        flex: 1;
      }
    }
  }

  .models-page__left,
  .models-page__right {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;

    .mm-select {
      padding-bottom: 0;
    }
  }

  .models-page__right {
    margin-top: 0;

    .calculate-inputs {
      flex-direction: column;
      padding: 24px 32px;

      .calculate-inputs__wrapper {
        flex-direction: row !important;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .calculate-inputs {
    flex-direction: column;

    &__wrapper {
      flex-direction: column;
    }

    .models-page__left {
      margin-bottom: 20px;
      flex-direction: column;

      .mm-body-regular-s {
        max-width: 318px !important;
        margin-bottom: 4px;
      }

      .mm-input {
        width: 155px;
      }
    }

    .models-page__right {
      flex-direction: column;
      width: 100%;

      .mm-body-regular-s {
        max-width: 318px !important;
        margin-bottom: 4px;
      }

      .mm-select {
        width: 100%;
      }

      .calc-type-select .vs__dropdown-toggle {
        width: 100% !important;
        flex: 1;
        max-width: unset;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .models-page {
    .calculate-inputs {
      margin-bottom: 4px;
      padding: 20px 16px;

      .calculate-inputs__wrapper {
        flex-direction: column;
      }

      .models-page__left {
        flex: 0;
        margin-bottom: 16px;
        margin-right: 20px;

        .calculate-inputs__label {
          width: auto !important;
          margin-right: 20px;
          max-width: max-content;
        }

        .mm-select {
          padding-bottom: 0;
        }
      }

      .models-page__right {
        flex: 1;
        padding-left: 0;

        .calc-type-select {
          max-width: 100%;
        }

        .calculate-inputs__label {
          padding-right: 0;
          margin-right: 20px;
          width: auto !important;
          max-width: max-content;
        }

        .mm-select {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
