<template>
  <div
    class="mm-images-gallery"
    :class="{
      'mm-images-gallery--vertical': position === EImageGalleryPosition.Vertical,
      'mm-images-gallery--small' : isSmallDesign,
    }"
  >
    <div
      v-for="(image, index) in displayedImages"
      :key="index"
      :class="[
        'mm-images-gallery__item',
        { 'mm-images-gallery__item--errored': !!image.error },
        { 'last': index === displayedImages.length - 1 && imagesRemainedCount < 0 }
      ]"
      @click="onImageClick(index)"
    >
      <div
        v-if="isShowOrder"
        class="gallery__index"
      >
        {{ index === 0 ? 'Заглавное фото' : (index > 8 ? index + 1 : `0${index + 1}`) }}
      </div>

      <div
        v-if="isActionButtons"
        class="gallery__item--actions"
      >
        <div class="gallery__item--buttons">
          <button
            class="gallery__action--button"
            :disabled="!!image.error"
            @click="onImageGalleryClick(index)"
          >
            <SvgIcon src="action/zoom-in" />
          </button>

          <template v-if="isAvailableEdit">
            <button
              class="gallery__action--button"
              :disabled="!!image.error"
              @click="onEditBtnClick(image)"
            >
              <SvgIcon src="action/pen-edit" />
            </button>

            <button
              class="gallery__action--button"
              @click="onDeleteImage(index)"
            >
              <SvgIcon src="action/trash" />
            </button>
          </template>
        </div>

        <div
          v-if="isAvailableEdit"
          class="gallery__item--arrows"
        >
          <button
            class="gallery__arrow--top"
            :disabled="isChangeOrderBtnDisabled(index, true)"
            @click="onTopBtnClick(index)"
          >
            <SvgIcon src="navigation/arrow-top-20px" />
          </button>

          <button
            class="gallery__arrow--bottom"
            :disabled="isChangeOrderBtnDisabled(index)"
            @click="onBottomBtnClick(index)"
          >
            <SvgIcon src="navigation/arrow-down-20px" />
          </button>
        </div>
      </div>

      <div class="mm-images-gallery__img-wrapper">
        <BaseImg
          v-if="ssr"
          :src="image.url"
          alt="product-gallery-img"
          class="mm-images-gallery__img"
        />

        <img
          v-else
          :src="image.url"
          alt="product-gallery-img"
          class="mm-images-gallery__img"
        >
      </div>
    </div>

    <div
      v-if="imagesRemainedCount > 0"
      class="mm-body-medium-s mm-images-gallery__item mm-images-gallery__btn"
      @click="onImageGalleryClick()"
    >
      Еще {{ imagesRemainedCount }} фото
    </div>

    <div
      v-if="isAvailableAdd && isAvailableEdit"
      class="mm-images-gallery__add mm-images-gallery__item"
      :class="{ 'mm-images-gallery__add--errored': !!imagesListError }"
    >
      <div class="add__inner">
        <button
          class="btn btn-text"
          @click="$emit('addImage')"
        >
          <SvgIcon src="action/plus" />
          {{ addCustomEmptyText ?? 'Добавить фото' }}
        </button>
      </div>
    </div>

    <div
      v-if="showCheckbox"
      class="mm-images-gallery__checkbox"
    >
      <Checkbox
        :model-value="checkboxValue"
        @update:model-value="$emit('update:checkbox-value', $event)"
      >
        {{ checkboxText }}
      </Checkbox>
    </div>

    <div
      v-if="isShowEmptyBlock && !images.length && !isAvailableAdd && !isAvailableEdit"
      class="mm-images-gallery__empty mm-images-gallery__item"
    >
      <div class="empty__inner">
        {{ emptyBlockText }}
      </div>
    </div>
  </div>

  <ClientOnly>
    <ImageViewerModal
      v-model="isImagesModalOpened"
      :images="imagesForModalView"
      :has-controllers="isMultipleDisplayedImages"
      :initial-index="currentImageIndex"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { IMaxMartImageItem } from '../../models/maxMartImageItem';
import ImageViewerModal from './ImageViewerModal.vue';
import { EImageGalleryPosition } from '../../enums/imageGalleryPosition.enum';
import SvgIcon from '../SvgIcon.vue';
import { IMedia } from '../../models/media.model';
import BaseImg from '../BaseImg';
import Checkbox from '../Checkbox.vue';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    images: Array<IMedia>;
    imagesListError?: string;
    displayedImagesCount?: number;
    position?: EImageGalleryPosition;
    emptyBlockText?: string;
    isShowOrder: boolean;
    isAvailableAdd: boolean;
    isActionButtons: boolean;
    isAvailableEdit?: boolean;
    isShowEmptyBlock?: boolean;
    isSmallDesign?: boolean;
    addCustomEmptyText?: string;
    showCheckbox?: boolean;
    checkboxText?: string;
    checkboxValue?: boolean;
    ssr?: boolean;
  }>(), {
    displayedImagesCount: 4,
    position: EImageGalleryPosition.Horizontal,
    emptyBlockText: 'Нет фото',
    checkboxText: 'Нет фотографий',
    isActionButtons: false,
    isAvailableEdit: true,
    isShowEmptyBlock: false,
    ssr: true,
  });

const emit = defineEmits<{
  (e: 'addImage');
  (e: 'update:images', images: Array<IMedia>);
  (e: 'editImage', image: IMedia);
  (e: 'update:checkbox-value', value: boolean);
}>();

const watchSubscription = new WatchSubscription();
const currentImageIndex = ref(0);
const internalImages = ref<Array<IMedia>>([]);
const isImagesModalOpened = ref(false);
const isMultipleDisplayedImages = ref(props.displayedImagesCount > 1);
const displayedImages = computed<Array<IMedia>>(() => internalImages.value.slice(0, props.displayedImagesCount));
const imagesRemainedCount = computed(() => internalImages.value.length - props.displayedImagesCount);
const imagesForModalView = computed<Array<string>>(() => internalImages.value.map((image) => image.url));

function onImageClick(imageIndex?: number) {
  if (props.isActionButtons) {
    return;
  }

  onImageGalleryClick(imageIndex);
}

function onImageGalleryClick(imageIndex?: number) {
  currentImageIndex.value = imageIndex ?? 0;
  isImagesModalOpened.value = true;
}

function onTopBtnClick(index: number): void {
  changeOrder(index - 1, index);
}

function onBottomBtnClick(index: number): void {
  changeOrder(index + 1, index);
}

function changeOrder(newOrder: number, oldOrder: number): void {
  const itemTemp = internalImages.value[newOrder];
  internalImages.value[newOrder] = internalImages.value[oldOrder];
  internalImages.value[oldOrder] = itemTemp;
  updateImages();
}

function updateImages(): void {
  emit('update:images', internalImages.value);
}

function onDeleteImage(_index: number): void {
  if (internalImages.value[_index].error) {
    internalImages.value[_index].error = '';
  }

  internalImages.value = internalImages.value.filter((_, index) => index !== _index);
  updateImages();
}

function setInternalImages(imageList: Array<IMaxMartImageItem>): void {
  internalImages.value = imageList || [];
}

function isChangeOrderBtnDisabled(index: number, isTop = false): boolean {
  return !props.isAvailableEdit || isTop && !index || !isTop && index === displayedImages.value.length - 1;
}

function onEditBtnClick(image: IMedia): void {
  emit('editImage', image);
}

watchSubscription.add(
  watch(
    () => props.images,
    () => (setInternalImages(props.images)),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

setInternalImages(props.images);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-images-gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid $dark-gray;
  border-radius: 8px;
  height: 150px;
  padding: 12px 0;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    padding: 8px 20px;
    cursor: pointer;
    position: relative;
    min-height: 247px;

    &--errored {
      border: 1px solid $red !important;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .gallery__index {
        background-color: $red !important;
        color: $text-white !important;
      }
    }

    &:hover {
      .gallery__item--actions {
        display: flex;
      }
    }
  }

  &__item.last {
    border: none;
  }

  &__btn {
    border: none;
    color: $link;
  }

  &__img-wrapper {
    height: 100%;
    max-height: 200px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .mm-images-gallery__img {
      max-height: 100%;
      max-width: 104px;
      width: 100%;
      height: -webkit-fill-available;
      object-fit: contain;
    }
  }

  &__add, &__empty {
    padding: 24px;

    &--errored {
      border: 1px solid $red !important;
      border-radius: 8px;

      &:has(+ .mm-images-gallery__checkbox) {
        margin-bottom: 24px;
      }
    }

    .add__inner, .empty__inner {
      background: $light-gray;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 199px;

      :deep(svg) {
        margin-right: 4px;
      }
    }
  }

  &__empty {
    cursor: default;
  }

  .gallery__index {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    background: $dark-gray;
    border-radius: 0 0 16px;
    padding: 3px 8px 5px;
    color: $text-light-green;
    z-index: 3;
  }

  &--vertical {
    flex-direction: column;
    height: auto;
    padding: 0;

    .mm-images-gallery__item {
      padding: 24px;
      border-bottom: 1px solid $dark-gray;

      &:first-child {
        .gallery__index {
          border-radius: 6px 0 16px;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:has(+ .mm-images-gallery__checkbox) {
        border-bottom: 0;
      }
    }
  }

  &--small {
    width: 124px;
    height: 124px !important;
    background-color: $text-white;
    overflow: hidden;

    .add__inner {
      min-height: 108px;
      max-height: 108px;
      width: 108px;
      background-color: $light-gray;
    }

    .gallery__item--arrows {
      display: none;
    }

    .gallery__action--button {
      margin-right: 13px;
      margin-top: -55px;
    }

    .gallery__item--actions {
      justify-content: flex-end;
      flex-direction: column;
    }

    .gallery__item--buttons {
      padding: 2px 12px 6px;
      border-radius: 0 0 6px 6px;
    }

    .mm-images-gallery__item {
      min-height: 124px;
      padding: 0;
      max-height: 124px;
    }

    .mm-images-gallery__img-wrapper {
      max-height: 124px;
    }

    .mm-images-gallery__add {
      min-height: 108px;

      .btn {
        flex-direction: column;
        padding: 0 10px;
        width: 124px;
        height: 124px;
        justify-content: center;
        white-space: pre-wrap;

        svg {
          margin-bottom: 8px;
        }
      }
    }

    .mm-images-gallery__btn {
      display: none;
    }
  }
}

.gallery {
  &__index {
    z-index: 10;
  }

  &__arrow {
    &--top {
      width: 40px;
      height: 40px;
      background: $dark-gray;
      border-radius: 0 0 0 16px;
      border: none;

      :deep(svg) {
        width: 20px;
      }
    }

    &--bottom {
      width: 40px;
      height: 40px;
      background: $dark-gray;
      border-radius: 16px 0 0;
      border: none;

      :deep(svg) {
        width: 20px;
      }
    }

    &--top:disabled, &--bottom:disabled {
      :deep(svg) {
        opacity: .3;
      }
    }
  }

  &__action {
    &--button {
      width: 24px;
      height: 24px;
      background-color: transparent;
      position: relative;
      border: none;
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      :deep(svg) {
        path,
        rect {
          fill: $white;
        }

        circle {
          stroke: $white;
        }
      }

      &:hover {
        :deep(svg) {
          path,
          rect {
            fill: $link;
          }

          circle {
            stroke: $link;
          }
        }
      }
    }
  }

  &__item {
    &--actions {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $background-white-8;
      align-items: center;
      justify-content: center;
    }

    &--buttons {
      z-index: 1;
      background: $text-dark-green;
      border-radius: 6px;
      padding: 8px 12px;
    }

    &--arrows {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.mm-images-gallery__checkbox {
  padding: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
